import React, {Component} from 'react';
import ReactDOM from 'react-dom'

class Overlay extends Component {
  componentDidMount() {
    let bg = document.querySelector('.overlay-background');
    bg.addEventListener('click', this.props.onClose);
  }

  componentWillUnmount() {
    let bg = document.querySelector('.overlay-background');
    bg.removeEventListener('click', this.props.onClose);
  }

  render() {
    let classes = ["overlay-content"];
    if (this.props.className) {
      classes.push(this.props.className);
    }

    return ReactDOM.createPortal(
      <React.Fragment>
        <div className={'overlay-background'} />
        <div className={classes.join(" ")}>
          {this.props.children}
        </div>
      </React.Fragment>
      , document.querySelector("body"));
  }
}

export default Overlay;