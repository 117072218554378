import React from 'react';

import GA from "react-ga";
import {history} from "../../store";
import config from "../../config";

const debug = process.env.NODE_ENV === 'development';

class GoogleAnalytics extends React.Component {
  constructor(props) {
    super(props);

    this.trackPageview = this.trackPageview.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
  }

  componentDidMount() {
    if (!config.analyticsId) {
      return;
    }

    window.addEventListener('trackEvent', this.trackEvent);

    if (process.env.NODE_ENV !== "production") {
      return;
    }

    GA.initialize(config.analyticsId, { debug: debug });
    this.unlisten = history.listen(this.trackPageview);

    this.trackPageview(window.location);
  }

  componentWillUnmount() {
    this.unlisten();
    window.removeEventListener('trackEvent', this.trackEvent);
  }

  trackPageview(loc) {
    if (process.env.NODE_ENV !== "production") {
      return;
    }

    GA.set({ page: loc.pathname + loc.search });
    GA.pageview(loc.pathname + loc.search);
  }

  trackEvent(e) {
    if (process.env.NODE_ENV !== "production") {
      return;
    }

    GA.event(e.detail);
  }

  render() {
    return (
      this.props.children
    );
  }
}

export default GoogleAnalytics;