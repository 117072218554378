import React, {Component} from 'react';
import { Link } from 'react-router-dom'

import youtube from "../../assets/images/youtube.png";
import twitch from "../../assets/images/twitch.png";
import smashcast from "../../assets/images/smashcast.png";
import chaturbate from "../../assets/images/chaturbate.png";
import facebook from "../../assets/images/facebook.png";
import mixer from "../../assets/images/mixer.png";
import livejasmin from "../../assets/images/livejasmin.png";


class GameTeaser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nsfw: this.props.nsfw === undefined || this.props.nsfw === true
    }
  }

  render() {
    return (
      <section className={'white-container logo-area'}>
        <div className={'container'}>
          <h4 className={'text-center dark fz-4 pt-3'}>supported streaming services</h4>
          <div className={'row'}>
            <div className={'col-12 col-md-3 offset-md-2 align-self-center'}>
              <Link to={"/top-streams"}><img src={twitch} alt={"Twitch"} className={'img-fluid'} /></Link>
            </div>
            <div className={'col-12 col-md-3 align-self-center'}>
              <img src={youtube} alt={"Youtube"} className={'img-fluid'} />
            </div>
            <div className={'col-12 col-md-3 align-self-center text-center'}>
              <img src={facebook} alt={"Facebook"} className={'img-fluid'} />
            </div>
            {this.state.nsfw && <div className={'col-12 col-md-4 align-self-center text-center padding-bottom-40'}>
              <Link to={"/chaturbate"}><img src={chaturbate} alt={"Chaturbate"} className={'img-fluid'} /></Link>
            </div>}
            {this.state.nsfw && <div className={'col-12 col-md-4 align-self-center text-center padding-bottom-40 padding-left-10-i padding-right-10-i'}>
              <img src={smashcast} alt={"Smashcast"} className={'img-fluid'} />
            </div>}
            {this.state.nsfw && <div className={'col-12 col-md-4 align-self-center text-center padding-bottom-40'}>
              <Link to={"/livejasmin"}><img src={livejasmin} alt={"Livejasmin"} className={'img-fluid'} /></Link>
            </div>}
          </div>
        </div>
      </section>
    );
  }
}

export default GameTeaser;