export function querySelect(query) {
  return Array.from(document.querySelectorAll(query));
}

export function elementWidth(elem) {
  let style = elem.currentStyle || window.getComputedStyle(elem),
    width = elem.offsetWidth, // or use style.width
    margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight),
    padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight),
    border = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);

  return (width + margin - padding + border);
}