import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import Gaming from "../components/LandingPage/Gaming";
import Adult from "../components/LandingPage/Adult";
import Game from "../components/LandingPage/Game";
import logo from '../components/Header/logo-v1.png';

class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.match.params.name.toLowerCase()
    }
  }


  render() {
    let content = "";

    switch (this.state.name) {
      case "chaturbate":
        content = <Adult title={"Chaturbate"} provider={"chaturbate"} />;
        break;
      case "livejasmin":
        content = <Adult title={"LiveJasmin"} provider={"livejasmin"} />;
        break;
      case "lol":
        content = <Game id={21779} title={"League of Legends"} />;
        break;
      case "fortnite":
        content = <Game id={33214} title={"FORTNITE"} />;
        break;
      case "gaming":
        content = <Gaming />;
        break;
      default:
        content = "TODO";
        break;
    }

    return (
      <div className={'landingpage'}>
        <div className={'header-overwrite'}>
          <Link to={"/"}><img src={logo} alt={"Logo"} /></Link>
        </div>
        <div className={'landing-content'}>
          {content}
        </div>
      </div>
    );
  }
}

export default LandingPage;