export function IsEmpty(obj) {
  if (!obj) {
    return true;
  }

  if (obj.constructor === Array) {
    return obj.length <= 0;
  }

  return IsObjectEmpty(obj);
}

export function IsObjectEmpty(obj) {
  if (!obj) {
    return true;
  }

  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export function NsfwEnabled() {
  if (window.localStorage['nsfw'] === undefined) {
    return false;
  }

  return window.localStorage['nsfw'] === 'false' ? false : true;
}

export function MobileUser() {
  return window.innerWidth < 768;
}