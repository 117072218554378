import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import WatchButton from "../Watchlist/WatchButton";

import placeholder from '../../assets/images/placeholder.png';

class StreamTeaser extends Component {
  render() {
    let { stream } = this.props;
    let url = stream.thumbnail_url.replace(/{width}/, 320).replace(/{height}/, 180);

    return (
      <div className={'stream-teaser twitch-teaser'}>
        <div className={'image-block'}>
          <h4>
            <Link to={`/watch?streams=twitch,${stream.login_name}`}>{stream.user_name} | {stream.title}</Link>
            <WatchButton provider={"twitch"} user={stream.user_name} login={stream.login_name} />
          </h4>

          <Link to={`/watch?streams=twitch,${stream.login_name}`}>
            <img src={placeholder} data-src={url} className={'img-fluid full lazyload'} alt={stream.login_name + " | " + stream.title} />
            <div className={'viewer-count'}>{stream.viewer_count.toLocaleString()}</div>
          </Link>
        </div>
      </div>
    );
  }
}

export default StreamTeaser;