import React, {Component} from 'react';

class Disclaimer extends Component {
  render() {
    return (
      <section className="container">
        <h1 className="section-headline">Imprint</h1>
        <br />
          <h2 className="section-headline">According to § 5 TMG:</h2><br />
          <p><strong>FOOBAR LTD.</strong><br />
            Mladost 3, bl.325, atelie 3 <br />
            1712, Sofia <br />
            Bulgaria
          </p>
          <h2 className="section-headline mt-2">Contact:</h2><br />
          <p>
            E-Mail: info@foo-bar.me
          </p>
          <h2>VAT-ID:</h2><br />
          <p>
            VAT identification number in accordance with §27 a VAT-Law:<br />
            BG203474009
          </p>
          <p>
            We assume no responsibility for external contents as well as contents of external links.
          </p>
      </section>
    );
  }
}

export default Disclaimer;