import { ACTIONS } from './constants'
import axios from 'axios/index'
import Api from "./api";

class TwitchApi extends Api {
  static fetchGame(id) {
    return dispatch => {
      dispatch({ type: ACTIONS.REQUEST.GAME });
      return axios.get(this.buildUrl('twitchGame'), {
        params: {
          id: id
        }
      }).then(json => dispatch({
        type: ACTIONS.RECEIVE.GAME,
        ...json.data
      }))
    }
  }

  static fetchTopGames(opts) {
    let page = opts.page || 1;
    let limit = opts.limit || 20;

    return dispatch => {
      dispatch({ type: ACTIONS.REQUEST.GAMES });
      return axios.get(this.buildUrl('topTwitchGames', opts), {
        params: {
          page: page,
          limit: limit
        }
      }).then(json => dispatch({
        type: ACTIONS.RECEIVE.GAMES,
        ...json.data
      }))
    }
  }

  static fetchTopStreams(opts) {
    let page = opts.page || 1;
    let limit = opts.limit || 20;
    let gameId = opts.gameId;

    return dispatch => {
      dispatch({ type: ACTIONS.REQUEST.STREAMS, gameId: gameId });
      return axios.get(this.buildUrl('topTwitchStreams', opts), {
        params: {
          game_id: gameId,
          page: page,
          limit: limit
        }
      }).then(json => dispatch({
        type: ACTIONS.RECEIVE.STREAMS,
        gameId: gameId,
        ...json.data
      }))
    }
  }
}

export function fetchGame(opts={}) {
  return TwitchApi.fetchGame(opts);
}

export function fetchTopGames(opts={}) {
  return TwitchApi.fetchTopGames(opts);
}

export function fetchTopStreams(opts={}) {
  return TwitchApi.fetchTopStreams(opts);
}