import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class WatchButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: this.isActive()
    };

    this.addToList = this.addToList.bind(this);
    this.toggleState = this.toggleState.bind(this);
  }

  isActive() {
    return (window.localStorage["watchlist"] ? JSON.parse(window.localStorage["watchlist"]) : []).indexOfObject(stream => stream.user === this.props.user && stream.provider === this.props.provider) > -1;
  }

  addToList(e) {
    e.preventDefault();
    window.dispatchEvent(new CustomEvent('addToList', { detail: { user: this.props.user, provider: this.props.provider, login: this.props.login } } ));
    this.setState({ active: this.isActive() });
  }

  toggleState(e) {
    this.setState({ active: this.isActive() });
  }

  componentDidMount() {
    window.addEventListener('removeFromList', this.toggleState);
  }

  componentWillUnmount() {
    window.removeEventListener('removeFromList', this.toggleState);
  }

  render() {
    return (
      <button className={'add-button'} onClick={this.addToList}>
        {this.state.active ? <FontAwesomeIcon icon={["fas", "minus"]} /> : <FontAwesomeIcon icon={["fas", "plus"]} />}
      </button>
    );
  }
}

export default WatchButton;