import React, {Component} from 'react';
import { withRouter } from 'react-router-dom'
import Stream from "../components/Stream/Stream";
import {Helmet} from "react-helmet";
import axios from "axios";
import Api from "../actions/api";

class Watch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      streams: this.getStreams(window.location.search),
      height: "100%",
      search: window.location.search,
      layout: window.localStorage["layout"] || "horizontal"
    };

    this.onResize = this.onResize.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.onStreamRemove = this.onStreamRemove.bind(this);
  }

  getStreams(search) {
    let params = new URLSearchParams(search);
    return params.get("streams").split("|").map((stream) => {
      let values = stream.split(",");
      return { provider: values[0].replace(" ", ""), user: values[1].replace(" ", "") };
    });
  }

  containerHeight() {
    return window.innerHeight - document.querySelector("header").clientHeight; // - document.querySelector("footer").clientHeight;
  }

  onResize() {
    this.setState({ height: this.containerHeight() + "px" });
  }

  onLayoutChange() {
    this.setState({ layout: window.localStorage["layout"] }, () => {
      window.dispatchEvent(new Event('calculateStreamsizes'))
    });
  }

  onStreamRemove() {
    this.setState({ streams: this.getStreams(window.location.search) });
  }

  trackStreams() {
    let streamParam = new URLSearchParams(window.location.search).get("streams");

    axios.get(Api.buildUrl('track'), {
      params: {
        streams: streamParam
      }
    });
  }

  componentDidMount() {
    this.setState({ height: this.containerHeight() + "px" });

    window.addEventListener('resize', this.onResize);
    window.addEventListener('changedLayout', this.onLayoutChange);
    window.addEventListener('streamRemoved', this.onStreamRemove);
    document.querySelector("footer").classList.add("hidden");
    document.querySelector(".stream-toggle").classList.add("visible");

    window.dispatchEvent(new CustomEvent('streamingStarted', { detail: { streams: this.state.streams } }));

    this._intervalCount = 1;
    this._interval = setInterval(() => {
      let params = new URLSearchParams(window.location.search);
      window.dispatchEvent(new CustomEvent('trackEvent', { detail: { category: "watch", action: params.get("streams") } }));
      window.dispatchEvent(new CustomEvent('trackEvent', { detail: { category: "WatchTime", action: (this._intervalCount * 30) + "" } }));
      this._intervalCount += 1;
    }, 30000);

    setTimeout(() => {
      let streamCount = this.state.streams.length + "";
      window.dispatchEvent(new CustomEvent('trackEvent', { detail: { category: "Stream-Count", action: streamCount } }));

      let providers = this.state.streams.map(stream => stream.provider.toLowerCase()).uniq().sort();
      window.dispatchEvent(new CustomEvent('trackEvent', { detail: { category: "Providers", action: providers.join(",") } }));
    }, 1000);

    this.trackStreams();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('changedLayout', this.onLayoutChange);
    window.removeEventListener('streamRemoved', this.onStreamRemove);
    document.querySelector("footer").classList.remove("hidden");
    document.querySelector(".stream-toggle").classList.remove("visible");
    window.dispatchEvent(new Event('streamingStopped'));
    clearInterval(this._interval);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.search !== window.location.search) {
      this.setState({ search: window.location.search, streams: this.getStreams(window.location.search) });
      this.trackStreams();
    }
  }

  render() {
    let { streams } = this.state;
    let classes = ["stream-page", 'streams-' + streams.length, this.state.layout];

    let names = streams.map(stream => stream.provider === "facebook" ? "Facebook Video" : stream.user).toSentence();

    return (
      <div className={classes.join(" ")} style={{ height: this.state.height }} data-count={streams.length}>
        <Helmet>
          <title>{names} | Multi-Stream</title>
          <meta name="description" content={`Watch ${names} at once right now on Multi-Stream.`} />
        </Helmet>

        {this.state.streams.map((stream, i) => {
          return <Stream key={stream.provider + stream.user} provider={stream.provider} user={stream.user} />
        })}
      </div>
    );
  }
}

export default withRouter(Watch);