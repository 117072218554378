import { ACTIONS } from '../actions/constants'

export function tags(state = {}, action) {
  let data = {};

  switch (action.type) {
    case ACTIONS.REQUEST.TAGS:
      return Object.assign({}, state, {
        loading: true
      });
    case ACTIONS.RECEIVE.TAGS:
      data = {};
      data[action.provider] = {
        data: action.data,
        loading: false
      };

      return Object.assign({}, state, data);
    default:
      return state
  }
}