import { combineReducers } from 'redux';
import { twitch } from './twitch';
import { chaturbate } from './chaturbate';
import { livejasmin } from "./livejasmin";
import { tags } from "./tags";

const reducers = {
  twitch, chaturbate, livejasmin, tags
};
export default combineReducers(reducers);
