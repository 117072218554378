import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { Link } from 'react-router-dom'

import {fetchTags} from "../../actions/tags";

class TagLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: window.innerWidth < 1024 ? 6 : 12
    };

    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    this.loadTags();

    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.provider !== this.props.provider) {
      this.loadTags();
    }
  }

  onResize() {
    this.setState({ limit: window.innerWidth < 1024 ? 6 : 12 });
  }

  loadTags() {
    const {fetchTags} = this.props;
    fetchTags({
      provider: this.props.provider,
      page: 1,
      limit: 12
    })
  }

  render() {
    if (this.props.loading) {
      return "";
    }

    return (
      <div className={'tag-line'}>
        Popular Tags:
        {this.props.data.slice(0, this.state.limit).map(tag => {
          return <Link key={tag.slug} to={`/${this.props.provider}/tag/${tag.slug}`}>{tag.name}</Link>
        })}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (!state.tags) {
    return { loading: true }
  }

  if (state.tags[ownProps.provider]) {
    return state.tags[ownProps.provider];
  }

  return { loading: true };
}

export default withRouter(connect(mapStateToProps, { fetchTags })(TagLine));