import React, {Component} from 'react';
import placeholder from "../../assets/images/placeholder.png";
import placeholderLarge from "../../assets/images/placeholder_large.png";
import placeholdeMedium from "../../assets/images/placeholder_medium.png";

class ObjectShell extends Component {
  render() {
    let classes = ["object-shell"];
    classes.push(this.props.size);

    let image = "";
    switch (this.props.size) {
      case "large":
        image = placeholderLarge;
        break;
      case "medium":
        image = placeholdeMedium;
        break;
      default:
        image = placeholder;
        break;
    }

    return (
      <div className={classes.join(" ")}>
        <div className={'image-block'}>
          <img src={image} className={'img-fluid full'} alt={"Placeholder"} />
        </div>
      </div>
    );
  }
}

export default ObjectShell;