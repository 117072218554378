import React, {Component} from 'react';
import StreamTeaser from "./StreamTeaser";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {fetchTopStreams} from "../../actions/twitch";
import SectionShell from "../Shell/SectionShell";

class StreamSection extends Component {
  needsReload() {
    let diff = (new Date().getTime() - this.props.lastLoadTime) / 1000;
    return this.props.loading || this.props.data.length !== this.props.limit || diff > 60
  }

  componentDidMount() {
    if (this.needsReload()) {
      this.loadStreams();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.page !== this.props.page) {
      this.loadStreams();
    }
  }

  loadStreams() {
    const {fetchTopStreams} = this.props;
    fetchTopStreams({
      gameId: this.props.gameId,
      page: this.props.page,
      limit: this.props.limit
    });
  }

  render() {
    let { data } = this.props;

    let grid = this.props.grid || 'col-6 col-md-20';

    if (this.props.loading || !data) {
      return <SectionShell key={'stream-section-shell'} count={this.props.limit} grid={grid} size={'default'} />;
    }

    return (
      <div className={'row full-gutters'}>
        {data.map((stream) => {
          return (
            <div key={stream.id} className={grid}>
              <StreamTeaser stream={stream} />
            </div>
          )
        })}
      </div>
    );
  }
}

StreamSection.defaultProps = {
  loading: true,
  limit: 22
};

function mapStateToProps(state, ownProps) {
  if (!state.twitch) {
    return { loading: true }
  }

  if (state.twitch.streams && state.twitch.streams[ownProps.gameId]) {
    return state.twitch.streams[ownProps.gameId];
  }

  return state.twitch.streams || { loading: true };
}

export default withRouter(connect(mapStateToProps, { fetchTopStreams })(StreamSection));