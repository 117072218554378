import React, {Component} from 'react';
import TwitchStreamSection from "../components/Twitch/StreamSection";
import {Helmet} from "react-helmet";
import {Link, withRouter} from "react-router-dom";

class TopStreams extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: this.currentPage()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.currentPage() !== this.state.page) {
      this.setState({ page: this.currentPage() });
    }
  }

  currentPage() {
    return (this.props.match.params.page || 1) * 1;
  }

  prevPageUrl() {
    return "/top-streams/" + (this.currentPage() - 1);
  }

  nextPageUrl() {
    if (this.currentPage() <= 0) {
      return "/top-streams";
    }

    return "/top-streams/" + (this.currentPage() + 1);
  }

  render() {
    return (
      <section className={'container default-padding'}>
        <Helmet>
          <title>Top Streams | Multi-Stream</title>
          <meta name="description" content={`Get an overview of the most popular Twitch streamers online right now.`} />
        </Helmet>

        <h1 className={'margin-bottom-20'}>Top Twitch Streams right now</h1>

        <TwitchStreamSection limit={40} grid={'col-6 col-sm-4 col-lg-3'} page={this.state.page} loading={true} />

        <Link to={this.nextPageUrl()} className={"btn btn-primary btn-lg  btn-block mb-2 mt-2"}>Next Page</Link>
        {this.currentPage() > 1  && <Link to={this.prevPageUrl()} className={"btn btn-primary btn-lg btn-block mb-2 mt-2"}>Prev Page</Link>}
      </section>
    );
  }
}

export default withRouter(TopStreams);