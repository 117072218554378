import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Overlay from "../Overlay/Overlay";
import StreamForm from "./StreamForm";

class StreamFormToggle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overlay: false
    };

    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler() {
    this.setState({ overlay: !this.state.overlay });
  }

  render() {
    return (
      <React.Fragment>
        <button className={'stream-toggle'} onClick={this.clickHandler}>
          <FontAwesomeIcon icon={"plus"} />
        </button>
        {
          this.state.overlay && (
            <Overlay onClose={this.clickHandler}>
              <div className={'header'}><button onClick={this.clickHandler}><FontAwesomeIcon icon={'times'}/></button></div>
              <div className={'inner-content full-padding'}>
                <StreamForm button={"Add Streams"} mode={'add'} onSubmit={this.clickHandler} />
              </div>
            </Overlay>
          )
        }
      </React.Fragment>
    );
  }
}

export default StreamFormToggle;