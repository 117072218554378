import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {history} from "../../store";
import axios from 'axios/index'
import Api from "../../actions/api";
import {MobileUser} from "../../helpers/global";

class Stream extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayChat: false,
      ratio: "16:9"
    };

    this.displayChat = this.displayChat.bind(this);
    this.removeStream = this.removeStream.bind(this);
    this.resizeIframe = this.resizeIframe.bind(this);
    this.checkAdblock = this.checkAdblock.bind(this);
  }

  componentDidMount() {
    window.dispatchEvent(new Event('clearWatchlist'));
    window.addEventListener('resize', this.resizeIframe);
    window.addEventListener('streamRemoved', this.resizeIframe);
    window.addEventListener('calculateStreamsizes', this.resizeIframe);

    this.checkAdblock();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeIframe);
    window.removeEventListener('streamRemoved', this.resizeIframe);
    window.removeEventListener('calculateStreamsizes', this.resizeIframe);
  }

  componentDidUpdate(prevProps, prevState) {
    this.resizeIframe();
  }

  resizeIframe() {
    if (this.props.provider !== "chaturbate") {
      return;
    }

    if (MobileUser()) {
      return;
    }

    axios.get(Api.buildUrl('aspect', { name: this.props.user }))
      .then((res) => {
        if (this.state.ratio === res.data) {
          return;
        }

        this.setState({ ratio: res.data })
      }
    );

    let stream = document.querySelector('#' + this.props.provider + "-" + this.props.user);
    if (!stream) {
      return;
    }

    let frame = document.querySelector('#' + this.props.provider + "-" + this.props.user + " iframe");
    if (!frame) {
      return;
    }

    let div1 = 1056; // 850 1056
    let div2 = 594; // 528 792

    if (this.state.ratio === "4:3") {
      div1 = 1056;
      div2 = 792;
    }

    let height = stream.clientHeight;
    // 1137x852
    let width  = ((div1 / div2) * height);

    let maxWidth = window.innerWidth;
    if (stream.clientWidth < window.innerWidth) {
      maxWidth = stream.clientWidth;
    }

    if (width < maxWidth) {
      frame.style.width = width + "px";
      frame.style.height = "100%";
      frame.style.top = "0px";
    } else {
      let width = stream.clientWidth;
      let height = ((div2 / div1) * width);
      frame.style.height = height + "px";
      frame.style.width = "100%";
      frame.style.top = ((stream.clientHeight - height) / 2) + "px";
    }
  }

  streamEmbed() {
    /*
    when "smashcast"
    url = "https://www.smashcast.tv/embed/#{username}?autoplay=true"
    when "youtube"
     */

    /*
    <iframe src="https://pto.ptawe.com/custom_iframe/index.php?psid=SimplyHentai&psprogram=revs&pstool=212_1&site=jasmin&cobrandid=&campaign_id=&category=girl&row=1&column=1&background=A60000&fill=0&border=0&model=insidehover&modelColor=0&modelFill=0&wide=1&padding=6px&width=0&height=0&imageWidth=0&imageHeight=0&stream=1&start=1&performerList=SapphirineEyes&subaffid={SUBAFFID}" scrolling="no" align="middle" frameborder="no" allowtransparency="true" marginHeight="0" marginWidth="0" style="position:absolute;top:0;left:0;width:100%;height:100%;background:transparent;"></iframe></div>
    <div id = "object_container" style="width:100%;height:100%"></div><script src="//awempt.com/embed/lfcht?c=object_container&site=jasmin&cobrandId=&psid=SimplyHentai&pstool=320_1&psprogram=revs&campaign_id=&category=girl&forcedPerformers[]=&preferredPerformers[]=MysteriousLora&vp[showChat]=true&vp[chatAutoHide]=true&vp[showCallToAction]=true&vp[showPerformerName]=false&vp[showPerformerStatus]=false&ctaLabelKey=spn&landingTarget=signup&filters=&subAffId={SUBAFFID}"></script>
    //ptentry.com/pu/?target=lf_chat&c=object_container&site=jasmin&cobrandId=&psid=SimplyHentai&pstool=320_1&psprogram=revs&campaign_id=&forcedPerformers%5B0%5D=&preferredPerformers%5B0%5D=MysteriousLora&vp%5BshowChat%5D=true&vp%5BchatAutoHide%5D=true&vp%5BshowCallToAction%5D=true&vp%5BshowPerformerName%5D=false&vp%5BshowPerformerStatus%5D=false&ctaLabelKey=spn&landingTarget=signup&filters=&subAffId=%7BSUBAFFID%7D&categoryName=girl
     */

    let url = "";
    switch (this.props.provider) {
      case "twitch":
        url = "https://player.twitch.tv/?channel=" + this.props.user + "&parent=www.multi-stream.tv";
        break;
      case "chaturbate":
        // 523x850
        url = "https://chaturbate.com/in/?track=embed&tour=Limj&campaign=iNlJT&signup_notice=0&b=" + this.props.user + "&1disable_sound=1&mobileRedirect=never";
        // <iframe src="https://chaturbate.com/in/?track=embed&tour=dTm0&campaign=iNlJT&disable_sound=1&embed_video_only=0&target=_parent&mobileRedirect=never&" height="523" width="850" frameBorder="0" className="code"></iframe>
        // <iframe src="https://chaturbate.com/in/?track=embed&tour=dTm0&campaign=iNlJT&disable_sound=1&mobileRedirect=never&embed_video_only=1" height="480px" width="850px" frameBorder="0" className="code" scrolling="no"></iframe>
        // <iframe src="https://chaturbate.com/in/?track=embed&tour=dTm0&campaign=iNlJT&disable_sound=1&embed_video_only=0&target=_parent&mobileRedirect=never&" height="523px" width="850px" frameBorder="0" className="code" scrolling="no"></iframe>

        /*if (MobileUser()) {
          url = "https://chaturbate.com/in/?track=embed&tour=9oGW&campaign=iNlJT&room=" + this.props.user + "&disable_sound=1&mobileRedirect=never&embed_video_only=1&target=_blank";
          url = "https://chaturbate.com/in/?track=embed&tour=9oGW&campaign=iNlJT&room=" + this.props.user + "&disable_sound=1&mobileRedirect=bever&signup_notice=0&embed_video_only=1&target=_blank";
        }*/

        /*url = "https://chaturbate.com/in/?track=embed&tour=9oGW&campaign=iNlJT&room=" + this.props.user + "&disable_sound=1&mobileRedirect=never&embed_video_only=1&target=_blank";

        if (this.state.displayChat) {
          url = "https://chaturbate.com/in/?track=embed&tour=Limj&campaign=iNlJT&signup_notice=0&b=" + this.props.user + "&1disable_sound=1&mobileRedirect=never";
        }*/
        break;
      case "livejasmin":
        // url = "https://pto.ptawe.com/custom_iframe/index.php?psid=SimplyHentai&psprogram=revs&pstool=212_1&site=jasmin&cobrandid=&campaign_id=&category=girl&row=1&column=1&background=0&fill=0&border=0&model=insidehover&modelColor=0&modelFill=0&wide=1&padding=6px&width=0&height=0&imageWidth=0&imageHeight=0&stream=1&start=1&performerList=" + this.props.user + "&subaffid=multi-stream";
        // url = "https://ptentry.com/pu/?target=lf_chat&c=object_container&site=jasmin&cobrandId=&psid=SimplyHentai&pstool=320_1&psprogram=revs&campaign_id=&forcedPerformers%5B0%5D=&preferredPerformers%5B0%5D=" + this.props.user + "&vp%5BshowChat%5D=true&vp%5BchatAutoHide%5D=true&vp%5BshowCallToAction%5D=true&vp%5BshowPerformerName%5D=false&vp%5BshowPerformerStatus%5D=false&ctaLabelKey=spn&landingTarget=signup&filters=&subAffId=multi-stream&categoryName=girl";
        url = Api.buildUrl('embed', { provider: this.props.provider, user: this.props.user });
        break;
      case "youtube":
        url = "https://gaming.youtube.com/embed/" +  this.props.user + "?autoplay=1";
        break;
      case "youtube-video":
        url = "https://www.youtube.com/embed/" + this.props.user + "?autoplay=1";
        break;
      case "smashcast":
        url = "https://www.smashcast.tv/embed/" + this.props.user + "?autoplay=true";
        break;
      case "mixer":
        url = "https://mixer.com/embed/player/" + this.props.user + "?disableLowLatency=1";
        break;
      case "pornhub":
        // <iframe src="https://de.pornhub.com/embed/live/mialee" frameBorder="0" width="560" height="315" scrolling="no" allowFullScreen></iframe>
        break;
      case "facebook":
        // https://www.facebook.com/klausgrillt/videos/322096091956717/
        // 500x280
        // url = "https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebook%2Fvideos%2F10153231379946729%2F&width=500&show_text=false&appId=227436200603826&height=280";
        // https://www.facebook.com/v2.8/plugins/video.php?allowfullscreen=true&app_id=128626020484753&autoplay=true&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fconnect%2Fxd_arbiter%2Fr%2FLviKjJ9cymB.js%3Fversion%3D43%23cb%3Df13984b4bda549c%26domain%3Dmarketingland.com%26origin%3Dhttps%253A%252F%252Fmarketingland.com%252Ffe8ae0e9f9947c%26relation%3Dparent.parent&container_width=800&href=https%3A%2F%2Fwww.facebook.com%2Fredbull%2Fvideos%2F10155801793140352%2F&locale=en_US&sdk=joey
        url = "https://www.facebook.com/plugins/video.php?href=" + this.props.user + "?allowfullscreen=true&autoplay=true";
        break;
      default:
        break;
    }
    return url;
  }

  checkAdblock() {
    if (this.props.provider === 'chaturbate' || this.props.provider === 'livejasmin') {
      axios.get(Api.buildUrl('embed', { provider: 'livejasmin', user: 'testuser' }))
        .catch(() => {
          window.dispatchEvent(new Event('displayAdblockNotice'));
        })
    }
  }

  chatEmbed() {
    switch (this.props.provider) {
      case "mixer":
        return `https://mixer.com/embed/chat/${this.props.user}`;
      default:
        return `https://www.twitch.tv/embed/${this.props.user}/chat`;
    }
  }

  displayChat(e) {
    e.preventDefault();
    this.setState({ displayChat: !this.state.displayChat });
  }

  removeStream(e) {
    e.preventDefault();

    let params = new URLSearchParams(window.location.search);
    let newParams = params.get("streams").split("|").filter(names => names !== this.props.provider + "," + this.props.user);
    history.replace("/watch?streams=" + newParams.join("|"));

    // let elem = document.querySelector('#' + this.props.provider + "-" + this.props.user);
    // elem.remove();

    let page = document.querySelector('.stream-page');
    let count = page.dataset.count * 1;

    if (count - 1 <= 0) {
      history.push("/");
    } else {
      page.classList.remove("streams-" + count);
      page.classList.add("streams-" + (count - 1));
      page.dataset.count = count - 1;

      window.dispatchEvent(new Event('streamRemoved'));
    }
  }

  render() {
    let classes = ["stream-container", this.props.provider];
    if (this.state.displayChat) {
      classes.push("display-chat");
    }

    return (
      <div className={classes.join(" ")} id={this.props.provider + "-" + this.props.user}>
        <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; encrypted-media" allowFullScreen src={this.streamEmbed()} title={this.props.user + " on " + this.props.provider} className={'stream-embed ' + this.props.provider} onLoad={this.resizeIframe} />
        {this.state.displayChat && this.props.provider !== "chaturbate" && <iframe title={this.props.user + " chatroom"} className={'chat-frame'} src={this.chatEmbed()} />}
        <div className={'button-wrapper'}>
          <button className={'btn btn-primary remove-btn'} onClick={this.removeStream}><FontAwesomeIcon icon={'times'}/></button>
          <button className={'btn btn-primary chat-btn'} onClick={this.displayChat}>Toggle Chat</button>
          <a className={'btn btn-primary link-btn'} href={`https://chaturbate.com/in/?track=multistream&tour=dT8X&campaign=iNlJT&room=${this.props.user}`} target={'_blank'}>Watch {this.props.user} on Chaturbate</a>
        </div>
      </div>
    );
  }
}

export default Stream;