import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {MobileUser} from "../../helpers/global";

class WatchlistToggle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "Watchlist"
    };

    this.onStreamStarted = this.onStreamStarted.bind(this);
    this.onStreamEnded = this.onStreamEnded.bind(this);
  }


  componentDidMount() {
    window.addEventListener('streamingStarted', this.onStreamStarted);
    window.addEventListener('streamingStopped', this.onStreamEnded);
  }

  componentWillUnmount() {
    window.removeEventListener('streamingStarted', this.onStreamStarted);
    window.removeEventListener('streamingStopped', this.onStreamEnded);
  }

  onStreamStarted() {
    this.setState({ text: "Settings" });
  }

  onStreamEnded() {
    this.setState({ text: "Watchlist" });
  }

  toggleWatchlist() {
    window.dispatchEvent(new Event('toggleWatchlist'))
  }

  render() {
    if (this.state.text === "Settings" && MobileUser()) {
      return "";
    }

    return (
      <div className="toggle-button">
        <button onClick={this.toggleWatchlist} className={'btn btn-default margin-left-10'}>
          <FontAwesomeIcon icon={'list-alt'} /> <span>{this.state.text}</span>
        </button>
      </div>
    );
  }
}

export default WatchlistToggle;