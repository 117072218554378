import React, {Component} from 'react';
import { Link } from 'react-router-dom'

import placeholder_large from '../../assets/images/placeholder_large.png';

class GameTeaser extends Component {
  render() {
    let { game } = this.props;

    let imageUrl = game.box_art_url.replace(/{width}/, 285).replace(/{height}/, 380);

    return (
      <Link to={`/game/${game.id}`} className={'game-teaser'}>
        <img src={placeholder_large} data-src={imageUrl} className={'img-fluid lazyload'} alt={game.name} />
        <h4>{game.name}</h4>
      </Link>
    );
  }
}

export default GameTeaser;