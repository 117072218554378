import React from 'react'
import {NsfwEnabled} from "../../helpers/global";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class NsfwSwitch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: NsfwEnabled()
    };

    this.switchNsfw = this.switchNsfw.bind(this);
    this.nsfwChange = this.nsfwChange.bind(this);
  }

  componentDidMount() {
    window.addEventListener('nsfwChanged', this.nsfwChange);
  }

  componentWillUnmount() {
    window.removeEventListener('nsfwChanged', this.nsfwChange);
  }

  switchNsfw(e) {
    e.preventDefault();
    let elems = document.querySelectorAll('.nsfw-filter input[type="checkbox"]');

    [].forEach.call(elems, function(elem) {
      elem.checked = !elem.checked;
    });

    let newState = !this.state.checked;

    this.setState({ checked: newState });

    window.localStorage['nsfw'] = newState;
    window.dispatchEvent(new CustomEvent("nsfwChanged", { detail: { nsfw: newState }}));
    window.dispatchEvent(new CustomEvent('trackEvent', { detail: { category: "NSFW", action: newState ? "on" : "off" } }));
  }

  nsfwChange(e) {
    this.setState({ checked: e.detail.nsfw });
  }

  render() {
    return (
      <div className="nsfw-filter toggle-button hidden-xs">
        <button onClick={this.switchNsfw} className={'btn btn-default'}>{this.state.checked ? <FontAwesomeIcon icon={"check"} /> : <FontAwesomeIcon icon={"times"} />} <span>NSFW</span></button>
      </div>
    )
  }
}

export default NsfwSwitch