import React, { Component } from 'react';

import './App.scss';
import Header from "../components/Header/Header";
import RouteList from '../routes';
import Watchlist from "../components/Watchlist/Watchlist";
import Footer from "../components/Footer/Footer";
import AdblockNotice from "../components/AdblockNotice/AdblockNotice";
import History from "../classes/History";

class App extends Component {
  constructor(props) {
    super(props);

    window.watchHistory = new History();
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    if (params.get("nsfw") === "1") {
      window.localStorage['nsfw'] = "true";
      window.dispatchEvent(new CustomEvent("nsfwChanged", { detail: { nsfw: true }}));
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={'wrapper'}>
          <Header />
          <RouteList />
        </div>
        <Footer/>
        <Watchlist/>
        <AdblockNotice/>
      </React.Fragment>
    );
  }
}

// export default App;
export default App