import { ACTIONS } from '../actions/constants'

export function chaturbate(state = {}, action) {
  let data = {};

  switch (action.type) {
    case ACTIONS.REQUEST.CAMS:
      return Object.assign({}, state, {
        cams: { loading: true }
      });
    case ACTIONS.RECEIVE.CAMS:
      data = {
        cams: {
          data: action.data,
          loading: false
        }
      };

      return Object.assign({}, state, data);
    default:
      return state
  }
}