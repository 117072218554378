import config from '../config'

class Api {
  static buildUrl(index, opts={}) {
    let url = config.apiUrl + config.version + config.urls[index];

    if (url.indexOf("%%") > -1) {
      for (let key in opts) {
        let value = opts[key];
        if (value && value.constructor === Array) {
          value = value.join(",")
        }

        url = url.replace("%%" + key, value);
      }
    }

    return url;
  }
}

export default Api;