import { ACTIONS } from './constants'
import axios from 'axios/index'
import Api from "./api";

class TagsApi extends Api {
  static fetchTags(opts) {
    let page = opts.page || 1;
    let limit = opts.limit || 20;
    let provider = opts.provider;

    return dispatch => {
      dispatch({ type: ACTIONS.REQUEST.TAGS });
      return axios.get(this.buildUrl('tags', opts), {
        params: {
          provider: provider,
          page: page,
          limit: limit
        }
      }).then(json => dispatch({
        type: ACTIONS.RECEIVE.TAGS,
        ...json.data
      }))
    }
  }
}

export function fetchTags(opts={}) {
  return TagsApi.fetchTags(opts);
}