import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {history} from "../../store";

class Watchlist extends Component {
  constructor(props) {
    super(props);

    let streams = window.localStorage["watchlist"] ? JSON.parse(window.localStorage["watchlist"]) : [];

    this.state = {
      streams: streams,
      layout: (window.localStorage["layout"] || "horizontal"),
      visible: streams.length > 0
    };

    this.addToList = this.addToList.bind(this);
    this.removeStream = this.removeStream.bind(this);
    this.clearList = this.clearList.bind(this);
    this.selectViewMode = this.selectViewMode.bind(this);
    this.toggleList = this.toggleList.bind(this);
  }

  addToList(e) {
    let streams = this.state.streams;
    let index = streams.indexOfObject(stream => stream.user === e.detail.user && stream.provider === e.detail.provider);

    if (index > -1) {
      streams.splice(index, 1);
    } else if (streams.length < 6) {
      streams.push(e.detail);
    }

    window.localStorage["watchlist"] = JSON.stringify(streams);

    this.setState({ streams: streams, visible: true });
  }

  removeStream(e) {
    let streams = this.state.streams;
    let target = e.target.closest("button");
    let index = streams.indexOfObject(stream => stream.user === target.dataset.user && stream.provider === target.dataset.provider);
    if (index > -1) {
      streams.splice(index, 1);
    }

    window.localStorage["watchlist"] = JSON.stringify(streams);

    window.dispatchEvent(new CustomEvent('removeFromList', { detail: { user: target.dataset.user, provider: target.dataset.provider }}));

    this.setState({ streams: streams, visible: streams.length > 0 });
  }

  clearList(e) {
    window.localStorage["watchlist"] = JSON.stringify([]);
    this.setState({ streams: [], visible: false });
  }

  selectViewMode(e) {
    e.preventDefault();

    window.localStorage["layout"] = e.currentTarget.dataset.layout;
    window.dispatchEvent(new Event('changedLayout'));

    this.setState({ layout: window.localStorage["layout"] });

    // console.log(e.currentTarget.dataset.mode);
  }

  toggleList() {
    this.setState({ visible: !this.state.visible });
  }

  componentDidMount() {
    window.addEventListener('addToList', this.addToList);
    window.addEventListener('clearWatchlist', this.clearList);
    window.addEventListener('toggleWatchlist', this.toggleList);
    window.addEventListener('streamingStarted', this.clearList);

    this._unlisten = history.listen(() => {
      if (this.state.visible && this.state.streams.length === 0) {
        this.setState({ visible: false });
      }
    })
  }

  componentWillUnmount() {
    window.removeEventListener('addToList', this.addToList);
    window.removeEventListener('clearWatchlist', this.clearList);
    window.removeEventListener('toggleWatchlist', this.toggleList);
    window.removeEventListener('streamingStarted', this.clearList);
    this._unlisten();
  }

  render() {
    if (!this.state.visible) {
      return "";
    }

    let streamParams = this.state.streams.map((stream) => {
      return stream.provider + "," + stream.login;
    }).join("|");

    let isHorizontal = this.state.layout === "horizontal";

    return (
      <div id={'watchlist'}>
        <div className={'watchlist-header'}>
          Watchlist
        </div>

        <div className={'stream-list'}>
          {this.state.streams.map((stream) => {
            return (
              <button onClick={this.removeStream} key={stream.user + stream.provider} data-user={stream.user} data-provider={stream.provider}>
                <FontAwesomeIcon icon={["fas", "trash"]} /> {stream.provider.capitalize()} | {stream.user}
              </button>
            );
          })}
        </div>

        <div className={'view-modes'}>
          <button className={"horizontal " + (isHorizontal ? 'active' : '')} onClick={this.selectViewMode} data-layout="horizontal">
            <div className={'image'} />
            horizontal
          </button>
          <button className={"vertical " + (isHorizontal ? '' : 'active')} onClick={this.selectViewMode} data-layout="vertical">
            <div className={'image'} />
            vertical
          </button>
        </div>

        {this.state.streams.length > 0 && (
          <div className={'start-container'}>
            <Link onClick={this.clearList} to={"/watch?streams=" + streamParams} className={'btn btn-primary btn-block start-button'}>Start Streaming</Link>
          </div>
        )}
      </div>
    );
  }
}

export default Watchlist;