import React, {Component} from 'react';
import { Link, withRouter } from 'react-router-dom'

import GameSection from "../components/Twitch/GameSection";
import {Helmet} from "react-helmet";

class Games extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: this.currentPage()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.currentPage() !== this.state.page) {
      this.setState({ page: this.currentPage() });
    }
  }

  currentPage() {
    return (this.props.match.params.page || 1) * 1;
  }

  prevPageUrl() {
    return "/games/" + (this.currentPage() - 1);
  }

  nextPageUrl() {
    if (this.currentPage() <= 0) {
      return "/games";
    }

    return "/games/" + (this.currentPage() + 1);
  }

  render() {
    return (
      <section className={'container default-padding'}>
        <Helmet>
          <title>Games | Multi-Stream</title>
          <meta name="description" content={`Get an overview of the most popular games to stream right now.`} />
        </Helmet>

        <h1 className={'margin-bottom-20'}>Top Games</h1>
        <GameSection grid={'col-6 col-md-3 col-lg-2'} limit={30} page={this.state.page} />

        <Link to={this.nextPageUrl()} className={"btn btn-primary btn-lg  btn-block mb-2 mt-2"}>Next Page</Link>
        {this.currentPage() > 1  && <Link to={this.prevPageUrl()} className={"btn btn-primary btn-lg  btn-block mb-2 mt-2"}>Prev Page</Link>}
      </section>
    );
  }
}

export default withRouter(Games);