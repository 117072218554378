import React, {Component} from 'react';
import Overlay from "../Overlay/Overlay";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class AdblockNotice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.onClose = this.onClose.bind(this);
    this.displayNotice = this.displayNotice.bind(this);
  }

  componentDidMount() {
    window.addEventListener('displayAdblockNotice', this.displayNotice);
  }

  componentWillUnmount() {
    window.removeEventListener('displayAdblockNotice', this.displayNotice);
  }

  displayNotice() {
    this.setState({ visible: true });
  }

  onClose() {
    this.setState({ visible: false });
  }

  render() {
    if (!this.state.visible) {
      return "";
    }

    return (
      <Overlay onClose={this.onClose} className={'small'}>
        <div className={'header'}><button onClick={this.onClose}><FontAwesomeIcon icon={'times'}/></button></div>
        <div className={'inner-content centered'}>
          <h1 className={'yellow'}>
            We can't display Chaturbate or LiveJasmin if you have an Adblocker enabled.<br />
            If you want to watch these kind of streams, please disable your Adblocker.
          </h1>
        </div>
      </Overlay>
    );
  }
}

export default AdblockNotice;