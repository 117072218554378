import React, {Component} from 'react';

import {Link} from "react-router-dom";
import TwitchStreamSection from "../Twitch/StreamSection";
import GameSection from "../Twitch/GameSection";
import StreamForm from "../Form/StreamForm";
import ProviderBox from "../ProviderBox/ProviderBox";


class Gaming extends Component {
  componentDidMount() {
    window.localStorage['nsfw'] = false;
    window.dispatchEvent(new CustomEvent("nsfwChanged", { detail: { nsfw: false }}));
  }

  render() {
    return (
      <div className={'gaming'}>
        <section className={'cover-block'}>
          <div className={'container'}>
            <div className={'cover-content'}>
              <h1>Watch multiple gaming streams <br />at the same time from Twitch</h1>
              <div className={'white margin-bottom-18'}>Add the up to 6 streaming url into the textfield below and start watching</div>
              <div className="row">
                <div className="col-12 offset-lg-3 offset-md-3">
                  <StreamForm />
                </div>  
              </div> 
            </div>
          </div>
        </section>
        <section className={'background-container'}>
          <div className={'container default-padding'}>
            <h3 className={'margin-bottom-25'}>Top Games</h3>

            <GameSection className={"overview"} limit={5} />

            <div className={'text-center margin-top-18'}>
              <Link to={"/games"} className={'btn btn-default'}>View more</Link>
            </div>
          </div>
        </section>

        <section className={'black-container'}>
          <div className={'container default-padding'}>
            <h3 className={'margin-bottom-25'}>Top gaming Streams live on Twitch</h3>

            <TwitchStreamSection limit={10} />

            <div className={'text-center margin-top-18'}>
              <Link to={"/top-streams"} className={'btn btn-default'}>View more</Link>
            </div>
          </div>
        </section>

        <ProviderBox nsfw={false} />
      </div>
    );
  }
}

export default Gaming;