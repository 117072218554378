import React, {Component} from 'react';
import { Link } from 'react-router-dom'

class Footer extends Component {
  render() {
    return (
      <footer className={'page-footer'}>
        <div className={'container'}>
          <div className={'pull-right'}>
            <Link to={"/disclaimer"}>Disclaimer</Link>
            <Link to={"/privacy"}>Privacy</Link>
            <Link to={"/faq"}>FAQ</Link>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;