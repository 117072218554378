import React from 'react';
import {NsfwEnabled} from "../../helpers/global";

class NsfwArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nsfw: NsfwEnabled()
    };

    this.nsfwChange = this.nsfwChange.bind(this);
  }

  componentDidMount() {
    window.addEventListener('nsfwChanged', this.nsfwChange);
  }

  componentWillUnmount() {
    window.removeEventListener('nsfwChanged', this.nsfwChange);
  }

  nsfwChange() {
    this.setState({ nsfw: NsfwEnabled() });
  }

  render() {
    if (!this.state.nsfw) {
      return "";
    }

    return (
      this.props.children
    );
  }
}

export default NsfwArea;