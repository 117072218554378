import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {Link, withRouter} from "react-router-dom";

import CamSection from "../components/Chaturbate/CamSection";
import LiveJasminCamSection from "../components/LiveJasmin/LiveJasminCamSection";
import TagLine from "../components/Tags/TagLine";

class Cams extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: this.currentPage()
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.currentPage() !== this.state.page) {
      this.setState({ page: this.currentPage() });
    }
  }

  currentPage() {
    return (this.props.match.params.page || 1) * 1;
  }

  prevPageUrl() {
    if (this.currentPage() - 1 <= 1) {
      return "/" + this.props.provider;
    }

    return "/" + this.props.provider + "/" + (this.currentPage() - 1);
  }

  nextPageUrl() {
    if (this.currentPage() <= 0) {
      return "/" + this.props.provider;
    }

    return "/" + this.props.provider + "/" + (this.currentPage() + 1);
  }

  render() {
    let camSection = "";
    let tagName = this.props.match.params.tag_name;

    switch (this.props.provider) {
      case "livejasmin":
        camSection = <LiveJasminCamSection grid={'col-6 col-md-4 col-lg-3'} limit={60} displayTeaser={true} page={this.state.page} tagName={tagName} />;
        break;
      default:
        camSection = <CamSection grid={'col-6 col-md-4 col-lg-3'} limit={60} displayTeaser={true} page={this.state.page} tagName={tagName} />;
        break;
    }

    return (
      <section className={'container default-padding'}>
        <Helmet>
          <title>{this.props.provider.capitalize()} Cams | Multi-Stream</title>
          <meta name="description" content={`Get an overview of the most popular cams on ${this.props.provider} right now.`} />
        </Helmet>

        <h1 className={'margin-bottom-20'}>Top {this.props.provider.capitalize()} Cams {tagName && 'tagged with ' + tagName}</h1>

        <TagLine provider={this.props.provider} />

        {camSection}

        <Link to={this.nextPageUrl()} className={"btn btn-primary btn-lg btn-block mb-2 mt-2"}>Next Page</Link>
        {this.currentPage() > 1  && <Link to={this.prevPageUrl()} className={"btn btn-primary btn-lg btn-block mb-2 mt-2"}>Prev Page</Link>}
      </section>
    );
  }
}

export default withRouter(Cams);