import React, {Component} from 'react';

import {Link} from "react-router-dom";
import image from './images/fortnite-cover.jpg';
import TwitchStreamSection from "../Twitch/StreamSection";
import StreamForm from "../Form/StreamForm";
import ProviderBox from "../ProviderBox/ProviderBox";


class Game extends Component {
  componentDidMount() {
    window.localStorage['nsfw'] = false;
    window.dispatchEvent(new CustomEvent("nsfwChanged", { detail: { nsfw: false }}));
  }

  render() {
    let backgroundImage = image;

    /*switch (this.props.id) {
      case "":
      break;
    }*/

    return (
      <div className={'game-landingpage'}>
        <section className={'cover-block'}>
          <div className={'container'}>
            <img src={backgroundImage} alt={"Cover"} className={'img-fluid full'} />
            <div className={'cover-content'}>
              
              <h1>Watch multiple {this.props.title} streams <br />from Twitch at the same time</h1>
              <div className={'white margin-bottom-18'}>Add the up to 6 streaming url into the textfield below and start watching</div>
              <div className="row">
                <div className="col-12 offset-lg-3 offset-md-3">
                  <StreamForm />
                </div>  
              </div> 
            </div>
          </div>
        </section>

        <section className={'background-container'}>
          <div className={'container default-padding'}>
            <h3 className={'margin-bottom-25'}>
              <Link to={"/game/" + this.props.id}>Top {this.props.title} Streams live on Twitch</Link>
            </h3>

            <TwitchStreamSection limit={20} gameId={this.props.id}/>

            <div className={'text-center margin-top-18'}>
              <Link to={"/game/" + this.props.id} className={'btn btn-default'}>View more</Link>
            </div>
          </div>
        </section>

        <ProviderBox nsfw={false} />
      </div>
    );
  }
}

export default Game;