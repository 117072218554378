import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import WatchButton from "../Watchlist/WatchButton";
import {NsfwEnabled} from "../../helpers/global";
import placeholder from "../../assets/images/placeholder_medium.png";
import nsfw from "../Chaturbate/nsfw.png";

class LiveJasminCamTeaser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nsfw: NsfwEnabled(),
      src: placeholder,
      force: this.props.force || false
    };

    this.nsfwChange = this.nsfwChange.bind(this)
  }

  componentDidMount() {
    window.addEventListener('nsfwChanged', this.nsfwChange);
  }

  componentWillUnmount() {
    window.removeEventListener('nsfwChanged', this.nsfwChange);
  }

  nsfwChange() {
    let isNsfw = NsfwEnabled();
    this.setState({ nsfw: isNsfw, src: (isNsfw ? this.props.cam.image : nsfw) });
  }

  render() {
    let { cam } = this.props;

    return (
      <div className={'stream-teaser livejasmin-teaser'}>
        <div className={'image-block'}>
          <h4>
            <Link to={`/watch?streams=livejasmin,${cam.username}`}>{this.state.nsfw || this.state.force ? `${cam.username}` : "NSFW Mode enabled"}</Link>
            <WatchButton provider={"livejasmin"} user={cam.username} login={cam.username} />
          </h4>

          <Link to={`/watch?streams=livejasmin,${cam.username}`}>
            <img src={this.state.src} data-src={this.state.nsfw || this.props.force ? cam.image : nsfw} className={'img-fluid full lazyload'} alt={cam.username + " | " + cam.title} />
          </Link>
        </div>
      </div>
    );
  }
}

export default LiveJasminCamTeaser;