import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import logo from './logo-v1.png';
import logo_mobile from './logo-v1-symbol.png';
import NsfwSwitch from "../NsfwSwitch/NsfwSwitch";
import StreamFormToggle from "../Form/StreamFormToggle";
import WatchlistToggle from "./WatchlistToggle";
import {NsfwEnabled} from "../../helpers/global";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nsfw: NsfwEnabled()
    };

    this.nsfwChange = this.nsfwChange.bind(this)
  }

  componentDidMount() {
    window.addEventListener('nsfwChanged', this.nsfwChange);
  }

  componentWillUnmount() {
    window.removeEventListener('nsfwChanged', this.nsfwChange);
  }

  nsfwChange() {
    let isNsfw = NsfwEnabled();
    this.setState({ nsfw: isNsfw });
  }

  render() {
    return (
      <header>
        <nav className={'container'}>
          <div className={'row'}>
            <div className={'col-4 col-md-8'}>
              <Link className={'logo'} to={"/"}>
                <img src={logo_mobile} alt={"Logo"} className={'logo-desktop'} />
                <img src={logo_mobile} alt={"Logo"} className={'logo-mobile'} />
              </Link>

              <Link to={"/games"}>Games</Link>
              <Link className={'d-none d-lg-inline-block'} to={"/top-streams"}>Top Streams</Link>
              {this.state.nsfw && <Link className={'d-none d-lg-inline-block'} to={"/chaturbate"}>Chaturbate</Link>}
              {this.state.nsfw && <Link className={'d-none d-xl-inline-block'} to={"/livejasmin"}>LiveJasmin</Link>}
              <Link className={'d-none d-xl-inline-block'} to={"/watch-history"}>History</Link>
            </div>

            <div className={'col-8 col-md-4 align-self-center text-right'}>
              <NsfwSwitch/>
              <WatchlistToggle />
              <StreamFormToggle />
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
