let config;
switch (process.env.NODE_ENV) {
  case "development":
    config = require("./development").default;
    break;
  default:
    config = require("./production").default;
    break;
}

export default config;