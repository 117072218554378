import { ACTIONS } from '../actions/constants'

export function twitch(state = {}, action) {
  let data = {}

  switch (action.type) {
    case ACTIONS.REQUEST.GAME:
      return Object.assign({}, state, {
        game: { loading: true }
      });
    case ACTIONS.RECEIVE.GAME:
      data = {
        game: {
          data: action.data[0],
          loading: false
        }
      };

      return Object.assign({}, state, data);
    case ACTIONS.REQUEST.GAMES:
      return Object.assign({}, state, {
        games: { loading: true }
      });
    case ACTIONS.RECEIVE.GAMES:
      data = {
        games: {
          data: action.data,
          loading: false
        }
      };

      return Object.assign({}, state, data);
    case ACTIONS.REQUEST.STREAMS:
      data = { streams: state.streams || {} };
      data.streams[action.gameId] = { loading: true };

      return Object.assign({}, state, data);
    case ACTIONS.RECEIVE.STREAMS:
      data = { streams: state.streams || {} };

      data.streams[action.gameId] = {
        data: action.data,
        lastLoadTime: new Date().getTime(),
        loading: false
      };

      return Object.assign({}, state, data);
    default:
      return state
  }
}