// Settings configured here will be merged into the final config object.
export default {
  analyticsId: "UA-88645174-1",
  apiUrl: "https://api.multi-stream.tv",

  version: "/v1/",
  urls: {
    twitchGame: "twitch/game",
    twitchGames: "twitch/games",
    topTwitchGames: "twitch/games/top",
    topTwitchStreams: "twitch/streams",

    tags: "tags",

    chaturbate: "cams?provider=chaturbate",
    livejasmin: "cams?provider=livejasmin",

    aspect: "cams/aspect-ratio/%%name",
    embed: "embed/%%provider.html?username=%%user",

    track: "track"
  }
};
