import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import StreamSection from "../components/Twitch/StreamSection";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {fetchGame} from "../actions/twitch";

class Game extends Component {
  componentDidMount() {
    const { fetchGame } = this.props;
    fetchGame(this.props.match.params.id)
  }

  render() {
    let { data } = this.props;

    if (this.props.loading) {
      return (
        <section className={'container'}>
          Loading
        </section>
      );
    }

    return (
      <section className={'container'}>
        <Helmet>
          <title>{data.name} | Multi-Stream</title>
          <meta name="description" content={`See all streamers, streaming ${data.name} right now.`} />
        </Helmet>

        <h1 className={'section-headline'}>{data.name} Top Streams</h1>
        <StreamSection gameId={this.props.match.params.id} grid={'col-6 col-sm-4 col-lg-3'} limit={40} />
      </section>
    );
  }
}

Game.defaultProps = {
  loading: true,
  data: {}
};

function mapStateToProps(state, ownProps) {
  if (!state.twitch) {
    return { loading: true }
  }

  return state.twitch.game || { loading: true };
}

export default withRouter(connect(mapStateToProps, { fetchGame })(Game));