import React, {Component} from 'react';
import WatchButton from "../Watchlist/WatchButton";
import {Link} from "react-router-dom";
import {NsfwEnabled} from "../../helpers/global";

import placeholder from '../../assets/images/placeholder_medium.png';
import nsfw from "./nsfw.png";

class CamTeaser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nsfw: NsfwEnabled(),
      src: placeholder,
      force: this.props.force || false
    };

    this.nsfwChange = this.nsfwChange.bind(this)
  }

  componentDidMount() {
    window.addEventListener('nsfwChanged', this.nsfwChange);
  }

  componentWillUnmount() {
    window.removeEventListener('nsfwChanged', this.nsfwChange);
  }

  nsfwChange() {
    let isNsfw = NsfwEnabled();
    this.setState({ nsfw: isNsfw, src: (isNsfw ? this.props.cam.image : nsfw) });
  }

  render() {
    let { cam } = this.props;

    return (
      <div className={'stream-teaser chaturbate-teaser'}>
        <div className={'image-block'}>
          <h4>
            <Link to={`/watch?streams=chaturbate,${cam.username}`}>{this.state.nsfw || this.state.force ? `${cam.username} | ${cam.title}` : "NSFW Mode enabled"}</Link>
            <WatchButton provider={"chaturbate"} user={cam.username} login={cam.username} />
          </h4>

          <Link to={`/watch?streams=chaturbate,${cam.username}`}>
            <img src={this.state.src} data-src={this.state.nsfw || this.props.force ? cam.image : nsfw} className={'img-fluid full lazyload'} alt={cam.username + " | " + cam.title} />
            <div className={'viewer-count'}>{cam.viewers.toLocaleString()}</div>
          </Link>
        </div>
      </div>
    );
  }
}

export default CamTeaser;