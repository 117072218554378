export const ACTIONS = {
  REQUEST: {
    GAMES: "REQUEST_GAMES",
    GAME: "REQUEST_GAME",
    STREAMS: "REQUEST_STREAMS",
    CAMS: "REQUEST_CAMS",
    LIVEJASMIN: "REQUEST_LIVEJASMIN",
    TAGS: "REQUEST_TAGS"
  },
  RECEIVE: {
    GAMES: "RECEIVE_GAMES",
    GAME: "RECEIVE_GAME",
    STREAMS: "RECEIVE_STREAMS",
    CAMS: "RECEIVE_CAMS",
    LIVEJASMIN: "RECEIVE_LIVEJASMIN",
    TAGS: "RECEIVE_TAGS"
  }
};