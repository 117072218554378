import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/promise';
import 'core-js/es/object';
import 'core-js/web/url-search-params';
import 'core-js/stable/dom-collections/for-each'

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import store, { history } from './store'
import App from './containers/App';
import axios from "axios/index";
import httpService from './actions/interceptor';
import ScrollToTop from "./components/ScrollToTop";
import GoogleAnalytics from "./components/Tracking/GoogleAnalytics";

import * as serviceWorker from './serviceWorker';

import "lazysizes/lazysizes.min";

import "./assets/icons.js";
import "./helpers/math.js";
import "./helpers/string.js";
import "./helpers/array.js";
import "./helpers/date.js";

window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.expand = 10;
window.lazySizesConfig.expFactor = 1.5;

axios.defaults.withCredentials = true;
httpService.setupInterceptors(store, history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <GoogleAnalytics>
          <App />
        </GoogleAnalytics>
      </ScrollToTop>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
)

serviceWorker.register();