import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import Home from "./containers/Home";
import Watch from "./containers/Watch";
import Game from "./containers/Game";
import Games from "./containers/Games";
import Faq from "./containers/Faq";
import TopStreams from "./containers/TopStreams";
import Disclaimer from "./containers/Disclaimer";
import Privacy from "./containers/Privacy";
import Cams from "./containers/Cams";
import LandingPage from "./containers/LandingPage";
import WatchHistory from "./containers/WatchHistory";

class RouteList extends Component {
  render() {
    return (
      <Switch>
        <Route path="/watch" component={Watch} />

        <Route path="/game/:id" component={Game} />

        <Route path="/games/:page" component={Games} />
        <Route exact path="/games" component={Games} />

        <Redirect from='/cams/:page' to='/chaturbate/:page'/>
        <Redirect from='/cams' to='/chaturbate'/>

        <Redirect from='/streams' to='/top-streams'/>

        <Route path="/chaturbate/tag/:tag_name" render={() => <Cams provider={'chaturbate'} />} />
        <Route path="/chaturbate/:page" render={() => <Cams provider={'chaturbate'} />} />
        <Route exact path="/chaturbate" render={() => <Cams provider={'chaturbate'} />} />

        <Route path="/livejasmin/tag/:tag_name" render={() => <Cams provider={'livejasmin'} />} />
        <Route path="/livejasmin/:page" render={() => <Cams provider={'livejasmin'} />} />
        <Route exact path="/livejasmin" render={() => <Cams provider={'livejasmin'} />} />

        <Route path='/top-streams/:page' component={TopStreams} />
        <Route exact path='/top-streams' component={TopStreams} />

        <Route exact path='/lp/:name' component={LandingPage} />

        <Route exact path='/privacy' component={Privacy} />
        <Route exact path='/disclaimer' component={Disclaimer} />
        <Route exact path='/faq' component={Faq} />
        <Route exact path='/watch-history' component={WatchHistory} />

        <Route exact path='/' component={Home} />

        <Route path='*' component={Home} />
      </Switch>
    )
  }
}

export default RouteList;
