import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import StreamForm from "../components/Form/StreamForm";
import GameSection from "../components/Twitch/GameSection";
import TwitchStreamSection from "../components/Twitch/StreamSection";

import ProviderBox from "../components/ProviderBox/ProviderBox";

import ChaturbateCamSection from "../components/Chaturbate/CamSection";
import LiveJasminCamSection from "../components/LiveJasmin/LiveJasminCamSection";

import {Helmet} from "react-helmet";
import NsfwArea from "../components/NsfwSwitch/NsfwArea";
import {history} from "../store";
import {NsfwEnabled} from "../helpers/global";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nsfw: NsfwEnabled()
    };

    this.nsfwChange = this.nsfwChange.bind(this)
  }

  componentDidMount() {
    window.addEventListener('nsfwChanged', this.nsfwChange);

    let path = window.location.pathname.split("/");
    let streams = [];

    path.forEach((p) => {
      if (p !== "") {
        streams.push("twitch," + p);
      }
    });

    if (streams.length > 0) {
      history.push("/watch?streams=" + streams.join("|"));
    }
  }

  componentWillUnmount() {
    window.removeEventListener('nsfwChanged', this.nsfwChange);
  }

  nsfwChange(e) {
    this.setState({ nsfw: e.detail.nsfw });
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Multi-Stream</title>
          <meta name="description" content="Stream up to 6 Twitch, Youtube, Chaturbate, LiveJasmin or Mixer live channel at once, Save your favourite stream url to share it with your friends." />
        </Helmet>

        <section className={'blue-container cover-container' + (this.state.nsfw ? ' nsfw' : '')}>
          <div className={'container padding-top-55 padding-bottom-30 text-center'}>
            <div className={'row'}>
              <div className={'col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3'}>
                <h2>THE MOST EASY AND FUNCTIONAL</h2>
                <h1 className={'fz-33 mb-2 mt-2'}>MULTI-STREAMING SERVICE</h1>
                <div className={'white margin-bottom-18'}>Add the up to 6 streaming url into the textfield below and start watching</div>

                <StreamForm />
              </div>
            </div>
          </div>
        </section>
        <ProviderBox />

        <NsfwArea>
          <section className={'black-container'}>
            <div className={'container default-padding'}>
              <h3 className={'margin-bottom-25'}><Link to={"/chaturbate"}>Chaturbate Cams</Link></h3>

              <ChaturbateCamSection limit={10} />

              <div className={'text-center margin-top-18'}>
                <Link to={"/chaturbate"} className={'btn btn-default'}>View more</Link>
              </div>
            </div>
          </section>

          <section className={'blue-container'}>
            <div className={'container default-padding'}>
              <h3 className={'margin-bottom-25'}><Link to={"/livejasmin"}>LiveJasmin Cams</Link></h3>

              <LiveJasminCamSection limit={10} />

              <div className={'text-center margin-top-18'}>
                <Link to={"/livejasmin"} className={'btn btn-default'}>View more</Link>
              </div>
            </div>
          </section>
        </NsfwArea>

        <section className={'black-container'}>
          <div className={'container default-padding'}>
            <h3 className={'margin-bottom-25'}><Link to={"/top-streams"}>Top Streams</Link></h3>

            <TwitchStreamSection limit={10} />

            <div className={'text-center margin-top-18'}>
              <Link to={"/top-streams"} className={'btn btn-default'}>View more</Link>
            </div>
          </div>
        </section>

        <section className={'blue-container'}>
          <div className={'container default-padding'}>
            <h3 className={'margin-bottom-25'}><Link to={"/games"}>Top Games</Link></h3>

            <GameSection className={"overview"} limit={5} />

            <div className={'text-center margin-top-18'}>
              <Link to={"/games"} className={'btn btn-default'}>View all</Link>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Home;