import React, {Component} from 'react';

import {querySelect} from "../../helpers/dom";
import {history} from "../../store";

class StreamForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 2
    };

    this.onFocus = this.onFocus.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onFocus(e) {
    let target = e.target;
    if (this.state.count >= 6) {
      return;
    }

    if (target.dataset.num * 1 === this.state.count - 1) {
      this.setState({ count: this.state.count + 1 });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    let streams = [];

    let elems = querySelect('.stream-url');
    for (let elem of elems) {
      if (elem.value === "") {
        continue;
      }

      let value = elem.value;
      if (value.indexOf("http") < 0) {
        value = "https://" + value;
      }

      let parser = document.createElement('a');
      parser.href = value;

      let params = new URLSearchParams(parser.search);
      let path = parser.pathname.replace(/(^\/|\/$)+/g, '');

      // Youtube Gaming, Smashcast
      switch (true) {
        case /twitch\.tv/.test(parser.hostname):
          streams.push("twitch," + path);
          break;
        case /chaturbate\.com/.test(parser.hostname):
          streams.push("chaturbate," + path);
          break;
        case /gaming\.youtube\.com/.test(parser.hostname):
          streams.push("youtube," + params.get("v"));
          break;
        case /www\.youtube\.com/.test(parser.hostname):
          streams.push("youtube-video," + params.get("v"));
          break;
        case /smashcast\.tv/.test(parser.hostname):
          streams.push("smashcast," + path);
          break;
        case /pornhub\.com/.test(parser.hostname):
          // https://de.pornhub.com/live/jade_ireland?track=6001
          break;
        case /facebook\.com/.test(parser.hostname):
          streams.push("facebook," + encodeURI(value));
          break;
        case /mixer\.com/.test(parser.hostname):
          // https://mixer.com/ECHAN
          streams.push("mixer," + path);
          break;
        case /livejasmin\.com/.test(parser.hostname):
          let name = value.split("/").pop().split("?").shift();
          streams.push('livejasmin,' + name);
          break;
        default:
          streams.push("twitch," + elem.value);
          break;
      }
    }

    switch (this.props.mode) {
      case "add":
        let params = new URLSearchParams(window.location.search);
        let current = params.get("streams").split("|");
        streams = current.concat(streams);
        break;
      default:
        break;
    }

    if (this.props.onSubmit) {
      this.props.onSubmit();
    }

    if (streams.length <= 0) {
      return false;
    }

    history.push("/watch?streams=" + streams.join("|"));

    return false;
  }

  render() {
    let inputs = [];
    let placeholders = ["https://twitch.tv/username", "https://gaming.youtube.com/watch?v=123456", "https://chaturbate.com/username"];

    for (let i = 0; i < this.state.count; i++) {
      let placeholder = placeholders[i] || "";
      inputs.push(<input onFocus={this.onFocus} type='text' key={"stream-" + i} name={"stream-" + i} className={'form-control stream-url'} placeholder={placeholder} data-num={i} />)
    }

    return (
      <form onSubmit={this.onSubmit} id={'stream-selector'}>
        {inputs}

        <button className={'mt-4 btn btn-primary btn-block btn-lg'}>{this.props.button || "Start Streaming"}</button>
      </form>
    );
  }
}

export default StreamForm;