import React, {Component} from 'react';

import cbCover from './images/cb-cover.png';
import ljCover from './images/lj-cover.jpg';
import ChaturbateCamSection from "../Chaturbate/CamSection";
import {Link} from "react-router-dom";
import TwitchStreamSection from "../Twitch/StreamSection";
import StreamForm from "../Form/StreamForm";

import { querySelect } from '../../helpers/dom';
import {history} from "../../store";
import ProviderBox from "../ProviderBox/ProviderBox";
import LiveJasminCamSection from "../LiveJasmin/LiveJasminCamSection";

class Adult extends Component {
  componentDidMount() {
    window.localStorage['nsfw'] = true;
    window.dispatchEvent(new CustomEvent("nsfwChanged", { detail: { nsfw: true }}));
  }

  onButtonClick() {
    window.dispatchEvent(new CustomEvent('trackEvent', { detail: { category: "landingpage", action: "stream-button" } }));

    let cbCams = querySelect('.cam-teasers .stream-teaser h4 a');
    let twitchStreams = querySelect('.stream-teasers .stream-teaser h4 a');

    let cbUsers = cbCams.slice(0, 2).map((elem) => {
      return elem.href.split("=").pop();
    });

    let twitchUsers = twitchStreams.slice(0, 2).map((elem) => {
      return elem.href.split("=").pop();
    });

    let streams = cbUsers.concat(twitchUsers);
    history.push("/watch?streams=" + streams.join("|"));
  }

  render() {
    let camSection = "";
    let backgroundImage = cbCover;
    let camLink = "/chaturbate";

    switch (this.props.provider) {
      case "livejasmin":
        camSection = <LiveJasminCamSection limit={10} force={true} />;
        backgroundImage = ljCover;
        camLink = "/livejasmin";
        break;
      default:
        camSection = <ChaturbateCamSection limit={10} force={true} />;
        break;
    }

    return (
      <div className={'chaturbate'}>
        <section className={'cover-block'}>
          <div className={'container'}>
            <img src={backgroundImage} alt={"Cover"} className={'img-fluid full'} />

            <div className={'cover-content'}>
              <h1>Watch GAMING & {this.props.title.toUpperCase()}<br />streams at the same time</h1>
              <button className={'btn btn-primary btn-lg'} onClick={this.onButtonClick}>Watch the Top Streams now &raquo;</button>
            </div>
          </div>
        </section>

        <section className={'background-container cam-teasers'}>
          <div className={'container default-padding'}>
            <h3 className={'margin-bottom-25'}>{this.props.title} Cams</h3>

            {camSection}

            <div className={'text-center margin-top-18'}>
              <Link to={camLink} className={'btn btn-default'}>View more</Link>
            </div>
          </div>
        </section>

        <section className={'black-container stream-teasers'}>
          <div className={'container default-padding'}>
            <h3 className={'margin-bottom-25'}>Top gaming Streams live on Twitch</h3>

            <TwitchStreamSection limit={10} />

            <div className={'text-center margin-top-18'}>
              <Link to={"/top-streams"} className={'btn btn-default'}>View more</Link>
            </div>
          </div>
        </section>

        <section className={'blue-container'}>
          <div className={'container padding-top-45 padding-bottom-68 text-center'}>
            <div className={'row'}>
              <div className={'col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3'}>
                <h3 className={'mb-4'}>Add your own stream urls</h3>

                <StreamForm />
              </div>
            </div>
          </div>
        </section>

        <ProviderBox />
      </div>
    );
  }
}

export default Adult;