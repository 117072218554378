import React, {Component} from 'react';
import { Link } from 'react-router-dom'

class WatchHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      streams: []
    }
  }

  componentDidMount() {
    const latest = window.watchHistory.latest();
    this.setState({
      streams: latest
    })
  }

  render() {
    const { streams } = this.state;

    return (
      <div className={'container'}>
        <h1 className={'mt-3 mb-3'}>Your latest Watch History</h1>

        {streams.map(stream => {
          return (
            <div key={Math.random()} className={'mb-3'}>
              <Link to={"/watch?streams=" + (stream.map(data => {
                return data.provider + "," + data.user
              }).join("|"))}>
                {stream.map(data => {
                  return [data.user, data.provider].join(" on ")
                }).join(", ")}
              </Link>
            </div>
          )
        })}
      </div>
    );
  }
}

export default WatchHistory;