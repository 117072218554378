/* eslint-disable */

Array.prototype.indexOfObject = function(callback) {
  for (let i = 0, len = this.length; i < len; i++) {
    if (callback(this[i])) {
      return i;
    }
  }
  return -1;
};

Array.prototype.toSentence = function(connector = 'and') {
  if (this.length <= 1) {
    return this.join("");
  }

  if (this.length > 2) {
    let length = (this.length * 2) - 3;
    for (let i = 1; i < length; i += 2) {
      this.splice(i, 0, ", ");
    }
  }

  this.splice(this.length - 1, 0, " " + connector + " ");

  return this.join("");
};

Array.prototype.uniq = function() {
  return this.sort().filter(function (item, pos, ary) {
    return !pos || item !== ary[pos - 1];
  });
};