import React, {Component} from 'react';

class Privacy extends Component {
  render() {
    return (
      <div className="container">
        <h2 className={'section-headline mt-2'}>Privacy Policy</h2>
        <p>Personal data (usually referred to just as "data" below) will only be processed by us to the extent necessary
          and for the purpose of providing a functional and user-friendly website, including its contents, and the
          services offered there.</p>
        <p>Per Art. 4 No. 1 of Regulation (EU) 2016/679, i.e. the General Data Protection Regulation (hereinafter
          referred to as the "GDPR"), "processing" refers to any operation or set of operations such as collection,
          recording, organization, structuring, storage, adaptation, alteration, retrieval, consultation, use,
          disclosure by transmission, dissemination, or otherwise making available, alignment, or combination,
          restriction, erasure, or destruction performed on personal data, whether by automated means or not.</p>
        <p>The following privacy policy is intended to inform you in particular about the type, scope, purpose,
          duration, and legal basis for the processing of such data either under our own control or in conjunction with
          others. We also inform you below about the third-party components we use to optimize our website and improve
          the user experience which may result in said third parties also processing data they collect and control.</p>
        <p>Our privacy policy is structured as follows:</p>
        <p>I. Information about us as controllers of your data<br />II. The rights of users and data subjects<br />III.
          Information about the data processing</p>
        <h3 className='section-headline mt-2'>I. Information about us as controllers of your data</h3>
        <p>The party responsible for this website (the "controller") for purposes of data protection law is:</p>
        <p>FOOBAR LTD.
          <br />Mladost 3, bl.325, atelie 3
            <br />1712, Sofia
              <br />Bulgaria</p>
        <h3 className='section-headline mt-2'>II. The rights of users and data subjects</h3>
        <p>With regard to the data processing to be described in more detail below, users and data subjects have the
          right</p>
        <ul type="disc">
          <li>to confirmation of whether data concerning them is being processed, information about the data being
            processed, further information about the nature of the data processing, and copies of the data (cf. also
            Art. 15 GDPR);
          </li>
          <li>to correct or complete incorrect or incomplete data (cf. also Art. 16 GDPR);</li>
          <li>to the immediate deletion of data concerning them (cf. also Art. 17 DSGVO), or, alternatively, if further
            processing is necessary as stipulated in Art. 17 Para. 3 GDPR, to restrict said processing per Art. 18 GDPR;
          </li>
          <li>to receive copies of the data concerning them and/or provided by them and to have the same transmitted to
            other providers/controllers (cf. also Art. 20 GDPR);
          </li>
          <li>to file complaints with the supervisory authority if they believe that data concerning them is being
            processed by the controller in breach of data protection provisions (see also Art. 77 GDPR).
          </li>
        </ul>
        <p>In addition, the controller is obliged to inform all recipients to whom it discloses data of any such
          corrections, deletions, or restrictions placed on processing the same per Art. 16, 17 Para. 1, 18 GDPR.
          However, this obligation does not apply if such notification is impossible or involves a disproportionate
          effort. Nevertheless, users have a right to information about these recipients.</p>
        <p><strong>Likewise, under Art. 21 GDPR, users and data subjects have the right to object to the controller's
          future processing of their data pursuant to Art. 6 Para. 1 lit. f) GDPR. In particular, an objection to data
          processing for the purpose of direct advertising is permissible.</strong></p>
        <h3 className='section-headline mt-2'>III. Information about the data processing</h3>
        <p>Your data processed when using our website will be deleted or blocked as soon as the purpose for its storage
          ceases to apply, provided the deletion of the same is not in breach of any statutory storage obligations or
          unless otherwise stipulated below.</p>

        <h4 className='section-headline mt-2'>Server data</h4>
        <p>For technical reasons, the following data sent by your internet browser to us or to our server provider will
          be collected, especially to ensure a secure and stable website: These server log files record the type and
          version of your browser, operating system, the website from which you came (referrer URL), the webpages on our
          site visited, the date and time of your visit, as well as the IP address from which you visited our site.</p>
        <p>The data thus collected will be temporarily stored, but not in association with any other of your data.</p>
        <p>The basis for this storage is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in the improvement,
          stability, functionality, and security of our website.</p>
        <p>The data will be deleted within no more than seven days, unless continued storage is required for evidentiary
          purposes. In which case, all or part of the data will be excluded from deletion until the investigation of the
          relevant incident is finally resolved.</p>

        <h4 className='section-headline mt-2'>Cookies</h4>
        <h5 className='section-headline mt-2'>a) Session cookies</h5>
        <p>We use cookies on our website. Cookies are small text files or other storage technologies stored on your
          computer by your browser. These cookies process certain specific information about you, such as your browser,
          location data, or IP address. &nbsp;</p>
        <p>This processing makes our website more user-friendly, efficient, and secure, allowing us, for example, to
          display our website in different languages or to offer a shopping cart function.</p>
        <p>The legal basis for such processing is Art. 6 Para. 1 lit. b) GDPR, insofar as these cookies are used to
          collect data to initiate or process contractual relationships.</p>
        <p>If the processing does not serve to initiate or process a contract, our legitimate interest lies in improving
          the functionality of our website. The legal basis is then Art. 6 Para. 1 lit. f) GDPR.</p>
        <p>When you close your browser, these session cookies are deleted.</p>
        <h5 className='section-headline mt-2'>b) Third-party cookies</h5>
        <p>If necessary, our website may also use cookies from companies with whom we cooperate for the purpose of
          advertising, analyzing, or improving the features of our website.</p>
        <p>Please refer to the following information for details, in particular for the legal basis and purpose of such
          third-party collection and processing of data collected through cookies.</p>
        <h5 className='section-headline mt-2'>c) Disabling cookies</h5>
        <p>You can refuse the use of cookies by changing the settings on your browser. Likewise, you can use the browser
          to delete cookies that have already been stored. However, the steps and measures required vary, depending on
          the browser you use. If you have any questions, please use the help function or consult the documentation for
          your browser or contact its maker for support. Browser settings cannot prevent so-called flash cookies from
          being set. Instead, you will need to change the setting of your Flash player. The steps and measures required
          for this also depend on the Flash player you are using. If you have any questions, please use the help
          function or consult the documentation for your Flash player or contact its maker for support.</p>
        <p>If you prevent or restrict the installation of cookies, not all of the functions on our site may be fully
          usable.</p>

        <h4 className='section-headline mt-2'>Google Analytics</h4>
        <p>We use Google Analytics on our website. This is a web analytics service provided by Google Inc., 1600
          Amphitheatre Parkway, Mountain View, CA 94043 (hereinafter: Google).</p>
        <p>Through certification according to the EU-US Privacy Shield</p>
        <p><a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active" target="_blank"
              rel="noopener noreferrer nofollow">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a></p>
        <p>Google guarantees that it will follow the EU's data protection regulations when processing data in the United
          States.</p>
        <p>The Google Analytics service is used to analyze how our website is used. The legal basis is Art. 6 Para. 1
          lit. f) GDPR. Our legitimate interest lies in the analysis, optimization, and economic operation of our
          site.</p>
        <p>Usage and user-related information, such as IP address, place, time, or frequency of your visits to our
          website will be transmitted to a Google server in the United States and stored there. However, we use Google
          Analytics with the so-called anonymization function, whereby Google truncates the IP address within the EU or
          the EEA before it is transmitted to the US.</p>
        <p>The data collected in this way is in turn used by Google to provide us with an evaluation of visits to our
          website and what visitors do once there. This data can also be used to provide other services related to the
          use of our website and of the internet in general.</p>
        <p>Google states that it will not connect your IP address to other data. In addition, Google provides further
          information with regard to its data protection practices at</p>
        <p><a href="https://www.google.com/intl/de/policies/privacy/partners" target="_blank"
              rel="noopener noreferrer nofollow">https://www.google.com/intl/de/policies/privacy/partners,</a></p>
        <p>including options you can exercise to prevent such use of your data.</p>
        <p>In addition, Google offers an opt-out add-on at</p>
        <p><a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
              rel="noopener noreferrer nofollow">https://tools.google.com/dlpage/gaoptout?hl=en</a></p>
        <p>in addition with further information. This add-on can be installed on the most popular browsers and offers
          you further control over the data that Google collects when you visit our website. The add-on informs Google
          Analytics' JavaScript (ga.js) that no information about the website visit should be transmitted to Google
          Analytics. However, this does not prevent information from being transmitted to us or to other web analytics
          services we may use as detailed herein.</p>

        <h4 className='section-headline mt-2'>Google Fonts</h4>
        <p>Our website uses Google Fonts to display external fonts. This is a service provided by Google Inc., 1600
          Amphitheatre Parkway, Mountain View, CA 94043 (hereinafter: Google).</p>
        <p>Through certification according to the EU-US Privacy Shield</p>
        <p><a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active" target="_blank"
              rel="noopener noreferrer nofollow">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a></p>
        <p>Google guarantees that it will follow the EU's data protection regulations when processing data in the United
          States.</p>
        <p>To enable the display of certain fonts on our website, a connection to the Google server in the USA is
          established whenever our website is accessed.</p>
        <p>The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in the optimization and economic
          operation of our site.</p>
        <p>When you access our site, a connection to Google is established from which Google can identify the site from
          which your request has been sent and to which IP address the fonts are being transmitted for display.</p>
        <p>Google offers detailed information at</p>
        <p><a href="https://adssettings.google.com/authenticated" target="_blank"
              rel="noopener noreferrer nofollow">https://adssettings.google.com/authenticated</a></p>
        <p><a href="https://policies.google.com/privacy" target="_blank"
              rel="noopener noreferrer nofollow">https://policies.google.com/privacy</a></p>
        <p>in particular on options for preventing the use of data.</p>

        <h4 className='section-headline mt-2'>YouTube</h4>
        <p>We use YouTube on our website. This is a video portal operated by YouTube LLC, 901 Cherry Ave, 94066 San
          Bruno, CA, USA, hereinafter referred to as "YouTube".</p>
        <p>YouTube is a subsidiary of Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, hereinafter
          referred to as "Google".</p>
        <p>Through certification according to the EU-US Privacy Shield</p>
        <p><a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active" target="_blank"
              rel="noopener noreferrer nofollow">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a></p>
        <p>Google and its subsidiary YouTube guarantee that they will follow the EU's data protection regulations when
          processing data in the United States.</p>
        <p>We use YouTube in its advanced privacy mode to show you videos. The legal basis is Art. 6 Para. 1 lit. f)
          GDPR. Our legitimate interest lies in improving the quality of our website. According to YouTube, the advanced
          privacy mode means that the data specified below will only be transmitted to the YouTube server if you
          actually start a video.</p>
        <p>Without this mode, a connection to the YouTube server in the USA will be established as soon as you access
          any of our webpages on which a YouTube video is embedded.</p>
        <p>This connection is required in order to be able to display the respective video on our website within your
          browser. YouTube will record and process at a minimum your IP address, the date and time the video was
          displayed, as well as the website you visited. In addition, a connection to the DoubleClick advertising
          network of Google is established.</p>
        <p>If you are logged in to YouTube when you access our site, YouTube will assign the connection information to
          your YouTube account. To prevent this, you must either log out of YouTube before visiting our site or make the
          appropriate settings in your YouTube account.</p>
        <p>For the purpose of functionality and analysis of usage behavior, YouTube permanently stores cookies on your
          device via your browser. If you do not agree to this processing, you have the option of preventing the
          installation of cookies by making the appropriate settings in your browser. Further details can be found in
          the section about cookies above.</p>
        <p>Further information about the collection and use of data as well as your rights and protection options in
          Google's privacy policy found at</p>
        <p><a href="https://policies.google.com/privacy" target="_blank"
              rel="noopener noreferrer nofollow">https://policies.google.com/privacy</a></p>

        <p><a href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html" target="_blank"
              rel="noopener noreferrer nofollow">Model Data Protection Statement</a> for <a href="https://www.ratgeberrecht.eu/"
                                                                        target="_blank" rel="noopener noreferrer nofollow">Anwaltskanzlei
          Weiß &amp; Partner</a></p>

      </div>
    );
  }
}

export default Privacy;