import React, {Component} from 'react';
import {NsfwEnabled} from "../../helpers/global";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {fetchTopCams} from "../../actions/chaturbate";
import CamTeaser from "./CamTeaser";
import SectionShell from "../Shell/SectionShell";

class CamSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nsfw: NsfwEnabled()
    };

    this.nsfwChange = this.nsfwChange.bind(this);
  }

  loadCams() {
    if (!this.state.nsfw && !this.props.force && !this.props.displayTeaser) {
      return;
    }

    const {fetchTopCams} = this.props;
    fetchTopCams({
      tagName: this.props.tagName,
      page: this.props.page,
      limit: this.props.limit
    })
  }

  componentDidMount() {
    window.addEventListener('nsfwChanged', this.nsfwChange);
    this.loadCams();
  }

  componentWillUnmount() {
    window.removeEventListener('nsfwChanged', this.nsfwChange);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.page !== this.props.page) {
      this.loadCams();
    }

    if (prevProps.tagName !== this.props.tagName) {
      this.loadCams();
    }
  }

  nsfwChange() {
    this.setState({ nsfw: NsfwEnabled() }, () => {
      if (!this.props.force) {
        this.loadCams();
      }
    });
  }

  render() {
    if (!this.state.nsfw && !this.props.force && !this.props.displayTeaser) {
      return "";
    }

    let { data } = this.props;
    let grid = this.props.grid || 'col-6 col-md-20';

    if (this.props.loading || !data) {
      return <SectionShell key={'cam-section-shell'} count={this.props.limit} grid={grid} size={'medium'} />;
    }

    return (
      <div>
        {this.props.headline}

        <div className={'row full-gutters'}>
          {data.map((cam) => {
            return (
              <div key={cam.username} className={grid}>
                <CamTeaser cam={cam} force={this.props.force} />
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}

CamSection.defaultProps = {
  loading: true,
  limit: 22,
  force: false
};

function mapStateToProps(state, ownProps) {
  if (!state.chaturbate) {
    return { loading: true }
  }

  if (state.chaturbate.cams) {
    return state.chaturbate.cams;
  }

  return state.chaturbate.cams || { loading: true };
}

export default withRouter(connect(mapStateToProps, { fetchTopCams })(CamSection));