import React, {Component} from 'react';
import ObjectShell from "./ObjectShell";

class SectionShell extends Component {
  render() {
    let { count, grid, size } = this.props;

    let elems = [];
    for (let i = 0; i < count; i++) {
      elems.push(
        <div className={grid} key={"shell-" + i}>
          <ObjectShell size={size} />
        </div>
      )
    }

    return (
      <div className={'row full-gutters'}>
        {elems}
      </div>
    );
  }
}

export default SectionShell;