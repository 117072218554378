import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";

import {fetchTopGames} from "../../actions/twitch";
import GameTeaser from "./GameTeaser";
import SectionShell from "../Shell/SectionShell";

class GameSection extends Component {
  needsReload() {
    return this.props.loading || this.props.data.length !== this.props.limit;
  }

  componentDidMount() {
    if (this.needsReload()) {
      this.loadGames();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.page !== this.props.page) {
      this.loadGames();
    }
  }

  loadGames() {
    const { fetchTopGames } = this.props;
    fetchTopGames({
      page: this.props.page,
      limit: this.props.limit
    })
  }

  render() {
    let grid = this.props.grid || 'col-6 col-md-20';

    if (this.props.loading) {
      return <SectionShell key={'game-section-shell'} count={this.props.limit} grid={grid} size={'large'} />;
    }

    let classes = ["row full-gutters game-section"];
    if (this.props.className) {
      classes.push(this.props.className);
    }

    return (
      <div className={classes.join(" ")}>
        {this.props.data.map((game) => {
          return (
            <div key={game.id} className={grid}>
              <GameTeaser game={game} />
            </div>
          )
        })}
      </div>
    );
  }
}

GameSection.defaultProps = {
  loading: true,
  limit: 22
};

function mapStateToProps(state, ownProps) {
  if (!state.twitch) {
    return { loading: true }
  }

  return state.twitch.games || { loading: true };
}

export default withRouter(connect(mapStateToProps, { fetchTopGames })(GameSection));