import axios from 'axios';

export default {
  setupInterceptors: (store, history) => {

    axios.interceptors.response.use(response => {
      return response;
    }, error => {

      // TODO add this if you want 404 fallback
      /*if (error.response.status === 401) {
        store.dispatch(logoutUser());
      }

      if (!error.response) {
        history.push("/network-error");
      }

      if (error.response && error.response.status === 404) {
        history.push('/not-found');
      }*/

      return Promise.reject(error);
    });
  },
};