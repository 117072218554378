import React, {Component} from 'react';

class Faq extends Component {
  render() {
    return (
      <section className="container">
        <div className={'widget'}>
          <div className={'widget-body'}>
            <h1 className="section-headline mb-2">
              Welcome to Multi-Stream.Tv!
            </h1>

            <div className={'text-center mb-2'}>
              You are free to use this site to watch up to 6 different Twitch.tv, Youtube Gaming, Chaturbate, Smashcast.tv or Facebook-Streams at the same time.
            </div>

            <div className="row no-gutters">
              <div className="col-md-6 col-sm-6">
                <h5 className={'section-headline'}>How to insert links</h5>
                <div className="feature">
                  <p>
                    Just copy &amp; paste the links of your favorite streamers in to the textfield on the Start page. You
                    can insert up to six streams simultaneously.<br /><br /><br />
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <h5 className={'section-headline'}>Supported platforms</h5>
                <div className="feature">
                  <p>
                    <a target="_blank" href="https://www.twitch.tv/" rel="nofollow noopener noreferrer">www.twitch.tv</a><br />
                    <a target="_blank" href="https://gaming.youtube.com/" rel="nofollow noopener noreferrer">www.gaming.youtube.com</a><br />
                    <a target="_blank" href="http://mixer.com/" rel="nofollow noopener noreferrer">mixer.com</a><br />
                    <a target="_blank" href="http://www.smashcast.tv/" rel="nofollow noopener noreferrer">www.smashcast.tv</a><br />
                    <a target="_blank" href="https://chaturbate.com/in/?track=default&tour=ZmU7&campaign=iNlJT" rel="nofollow noopener noreferrer">www.chaturbate.com</a><br />
                    <a target="_blank" href="https://awejmp.com/?siteId=jasmin&categoryName=&pageName=home&performerName=&prm[psid]=SimplyHentai&prm[pstool]=205_1&prm[psprogram]=revs&prm[campaign_id]=&subAffId=multi-stream&filters=" rel="nofollow noopener noreferrer">www.livejasmin.com</a><br />
                    <a target="_blank" href="https://www.facebook.com/" rel="nofollow noopener noreferrer">www.facebook.com</a>
                  </p>
                </div>
              </div>
            </div>

            <div className="row no-gutters">
              <div className="col-md-6 col-sm-6">
                <h5 className={'section-headline'}>Stream sizing</h5>
                <div className="feature">
                  <p>
                    You dont need to size anything. Multi-stream will optimize the layout automatically, while maintaining
                    aspect ratio.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <h5 className={'section-headline'}>Link format </h5>
                <div className="feature">
                  <p>
                    <strong>Twitch = </strong> https://www.twitch.tv/streamname (Full link) or just the <b>username</b><br />
                    <strong>Mixer = </strong> http://mixer.com/streamname (Full link)<br />
                    <strong>Youtube = </strong> https://gaming.youtube.com/watch?streamid (Full link)<br />
                    <strong>Smashcast = </strong> http://www.Smashcast.tv/streamname (Full link)<br />
                    <strong>Chaturbate = </strong> http://chaturbate.com/streamname (Full link)<br />
                    <strong>LiveJasmin = </strong> https://www.livejasmin.com/en/girls/#!chat/streamname (Full link)<br />
                    <strong>Facebook = </strong> https://www.facebook.com/PAGENAME/videos/VIDEO-ID/ (Full link)
                  </p>
                </div>
              </div>
            </div>

            <div className="row no-gutters">
              <div className="col-md-6 col-sm-6">
                <h5 className={'section-headline'}>Start the streams</h5>
                <div className="feature">
                  <p>
                    The streams are starting automatically. Do not start the stream manually, the manual start/stopping can
                    cause sync problems.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <h5 className={'section-headline'}>Games &amp; Top stream section</h5>
                <div className="feature">
                  <p>
                    You can pick up to 6 streams out of our top stream section or out of a specific game you want to
                    multi-stream. Just click the plus-icon to add it to your list of streams.<br />
                    To remove click the minus-icon. If you completed your list just hit the "Start streaming" button in the
                    lower right corner.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="section-headline mt-3">Have fun &amp; Happy streaming with Multi-stream.tv</h2>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Faq;