class History {
  constructor() {
    window.addEventListener('streamingStarted', this.onStreamingStarted.bind(this))
  }

  onStreamingStarted(e) {
    let current = (window.localStorage["history"] ? JSON.parse(window.localStorage["history"]) : []);
    if (current[current.length - 1] === e.detail.streams) {
      return;
    }

    current.push(e.detail.streams);

    while (current.length > 30) {
      current.shift();
    }

    window.localStorage["history"] = JSON.stringify(current);
  }

  latest() {
    return window.localStorage["history"] ? JSON.parse(window.localStorage["history"]) : [];
  }
}

export default History;